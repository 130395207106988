import { Dialog } from "@mui/material";

export const SpinModal = ({
  onEntered,
}: {
  onEntered?: (node: HTMLElement, isAppearing: boolean) => void;
}) => (
  <Dialog
    open
    fullScreen
    maxWidth={false}
    sx={{ zIndex: 99999999 }}
    TransitionProps={{
      onEntered: onEntered,
    }}
    PaperProps={{ style: { backgroundColor: "transparent" } }}
  >
    <div className="flex-row-center w-full h-full bg-textcolor/black/10">
      <div className="flex-col-center w-[100px] h-[100px]">
        <div className="dots-loader" />
      </div>
    </div>
  </Dialog>
);
